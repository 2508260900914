//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
//rotas
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Qrcode from "./pages/qrcode";
import Senac from "./pages/senac";
import Login from "./pages/senac/login";
import LoginPetro from "./pages/prpetro/login";
import Staff from "./pages/senac/staff";
import PetroStaff from "./pages/prpetro/staff";
import PetroReport from "./pages/prpetro/report";
import ReportParticipants from "./pages/senac/reportFile";
import QrcodeSenac from "./pages/qrcodeSenac";
import QrcodeParanaPetro from "./pages/qrcodeParanaPetro";
import QrCodeDiversa from "./pages/qrcodeDiversa";
import QrcodeSomosLev from "./pages/somoslev";
import QrcodeGS1 from "./pages/qrcodeGS1";

import PrivateRoute from "./utils/auth.routes";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/danoneday" element={<Qrcode />} />
          <Route path="/senac" element={<Senac />} />
          <Route path="/intterconnected" element={<QrcodeSenac />} />
          <Route path="/paranapetro" element={<QrcodeParanaPetro />} />
          <Route path="/diversa" element={<QrCodeDiversa />} />
          <Route path="/loginsenac" element={<Login />} />
          <Route path="/loginprpetro" element={<LoginPetro />} />
          <Route
            path="/staffprpetro"
            element={<PrivateRoute Component={PetroStaff} />}
          />
          <Route
            path="/reportprpetro"
            element={<PrivateRoute Component={PetroReport} />}
          />
          <Route
            path="/staffsenac"
            element={<PrivateRoute Component={Staff} />}
          />
          <Route
            path="/reportstaff"
            element={<PrivateRoute Component={ReportParticipants} />}
          />
          <Route path="/somoslev" element={<QrcodeSomosLev />} />
          <Route path="/bremcodigo" element={<QrcodeGS1 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
